import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

class Data extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title={`LACK | Data Security`}
          keywords={[`techno`, `label`, `records`]}
        />
        <div className="page data">
          <h1>Privacy Information</h1>
          <p>
            Claudio Banti, Hermannstraße, 12051 Berlin (hereinafter “we”, “us”
            and “our”) runs this website and therefore processes personal data
            pursuant to the EU General Data Protection Regulation (“GDPR”). You
            may reach out to us by writing a letter to the aforementioned
            address or via e-mail: info@lackrec.com. Where we use the term
            "data", we are referring to personal data within the meaning of the
            GDPR.
          </p>{' '}
          <br /> <br />
          <p>1. Website Visitors </p>
          <p>a) Server protocol data</p> <br />
          <p>
            Our web server processes a series of data for each request, which
            your browser automatically transmits to our web server. This data
            comprises the IP address currently assigned to your device, the date
            and time of the request, the time zone, the specific page or file
            called up, the http status code and the amount of data transferred.
            Additionally, the website from which your request came, the browser
            used, the operating system of your end device and the set language.
            The web server uses this data to display the contents of this
            website in the best possible way on your device.
          </p>
          <p>
            b) The purpose of data processing is to present us on the internet
            and to communicate with our fans, customers and business partners.
            The purpose of analyzing some user behavior on our website is to
            design the website in line with the requirements of our users and
            website visitors.
          </p>{' '}
          <p>
            c) The legal basis for processing the data is Art. 6 (1) lit. b)
            GDPR based on the contract of use of our website. The legal basis
            for the analysis of user behavior is Art. 6 (1) lit. f) GDPR, our
            legitimate interest is to provide our visitors with a
            demand-oriented design of our website.
          </p>{' '}
          <p>
            d) It is not possible to use our website without disclosure of
            protocol data such as your IP address. Protocol and communication
            data will not be transferred to third parties unless special
            circumstances require us to do so, e.g. if a criminal offence is
            suspected or in the course of an investigation. In these cases, data
            may be transferred to the police and/or the public prosecutor's
            office.
          </p>
          <br />
          <p>2. Communication and Contractual Obligations</p>{' '}
          <p>
            a) When you reach out to us via email or otherwise, we process the
            data you provide us with for the purpose of performing our
            obligations with regard to our contractual relationship or its
            preparation and/or any other communication purposes. This also
            includes consulting and information requests.
          </p>{' '}
          <p>
            b) In this event, the legal basis for processing your data is Art. 6
            (1) lit. b) GDPR based on a contractual relationship or the
            initiation of a contractual relationship and/or Article 6 (1) lit.
            c) GDPR where we are legally obliged to do so, in particular
            pursuant to applicable tax and commercial law provisions. In any
            case, the legal basis for processing your contact data is Article 6
            (1) lit. f) GDPR, our legitimate interest is to enter into the
            communication with you.
          </p>{' '}
          <p>
            d) All data relevant to contracts and book keeping shall be stored
            for a period of ten (10) calendar years after the end of our
            contractual relationship pursuant to obligations based on tax and
            commercial law provisions.
          </p>{' '}
          <p>
            e) The provision of your contact data is obligatory where you want
            us to enter into a communication with you. Contractual relationships
            cannot be established and carried out without the basic contractual
            data.
          </p>
          <br />
          <p>3. Rights of Data Subjects</p>{' '}
          <p>
            a) We do not use any methods of automated individual
            decision-making.
          </p>{' '}
          <p>
            b) At any time, you have the right to request information about all
            your Data which we are processing. Furthermore, if your Data is
            incorrect or incomplete, you have the right to have it rectified and
            completed. You can also request the erasure of your Data at any
            time, unless we are legally obliged to continue processing your
            Data.
          </p>{' '}
          <p>
            c) Where applicable legal requirements are met, you can request a
            restriction to the processing of your Data. You also have the right
            to object to the processing, insofar as the data processing is based
            on profiling or direct marketing purposes.
          </p>{' '}
          <p>
            d) Where we process your Data on the basis of your consent or a
            contract, you have the right to transfer the Data provided by you,
            insofar as the rights and freedoms of others remain unaffected.
            Where we process your Data on the basis of a declaration of consent,
            you have the right to revoke such consent at any time with future
            effect. The processing carried out prior to a revocation remains
            unaffected by the revocation.
          </p>{' '}
          <p>
            e) Where you believe that we process your Data in violation of the
            applicable law, you have the right to file a complaint with a data
            protection supervisory authority at any time.
          </p>
        </div>
      </Layout>
    )
  }
}

export default Data
